interface TextDimensions {
	fontSize: string;
	lineHeight: string;
}

interface IDimensions {
	largeTitle: TextDimensions;
	regularTitle: TextDimensions;
	smallTitle: TextDimensions;
	largeText: TextDimensions;
	regularText: TextDimensions;
	smallText: TextDimensions;
	tinyText: TextDimensions;
	extraTinyText: TextDimensions;
}

export const defaultWidth = '1200px';
export const narrowWidth = '800px';
export const extraNarrowWidth = '600px';

const Dimensions: IDimensions = {
	largeTitle: {
		fontSize: '3.5rem',
		lineHeight: '1.5em',
	},
	regularTitle: {
		fontSize: '2.5rem',
		lineHeight: '1.5em',
	},
	smallTitle: {
		fontSize: '2rem',
		lineHeight: '1.5em',
	},
	largeText: {
		fontSize: '1.5rem',
		lineHeight: '1.5em',
	},
	regularText: {
		fontSize: '1.25rem',
		lineHeight: '1.6em',
	},
	smallText: {
		fontSize: '1rem',
		lineHeight: '1.5em',
	},
	tinyText: {
		fontSize: '0.875rem',
		lineHeight: '1.4285714285714286em',
	},
	extraTinyText: {
		fontSize: '0.75em',
		lineHeight: '1.3333333333333333em',
	},
};

export default Dimensions;
