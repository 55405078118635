enum FiraSans {
	bold = 'FiraSans-Bold',
	book = 'FiraSans-Book',
	light = 'FiraSans-Light',
	medium = 'FiraSans-Medium',
	regular = 'FiraSans-Regular',
	semiBold = 'FiraSans-SemiBold',
}

export default FiraSans;
